
import React, { useEffect, useState} from 'react'

import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SEO from '../components/seo';
import logo from "../images/logo.svg";
import down from "../images/down.svg";

import { Link } from "react-scroll";


function IndexPage() {
  const [scrollY, setScrollY] = useState(0);

  const [active, setActive] = useState([]);

  const addOrRemove = (arr, item) => arr.includes(item) ? arr.filter(i => i !== item) : [ ...arr, item ];


  function logit() {
    setScrollY(window.pageYOffset > (window.innerHeight / 2));
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", logit);
    };
  }, []);



  const people = [
    {
      'name': 'Lynn Bruce',
      'position': 'Director',
      'words': 'Lynn Bruce is a Chartered Accountant (Scotland) having trained at KPMG, London. She was the CFO of an international wealth management group, Stenham Limited, for 11 years where she was also a member of both their Risk and Audit Committees. Prior to that she was Financial Controller at AT&T Capital Europe.  She holds a B.Sc. Hons in Business Mathematics and Accountancy from Dundee University. Lynn is a Guernsey resident.'
    }, {
      'name': 'Howard Shore',
      'position': 'Director',
      'words': 'Howard Shore founded Puma Brandenburg in 2006, initially as a listed property investment vehicle focused on German real estate. He has subsequently taken it private, and it is now owned by Howard and his wife. Puma Brandenburg is the main vehicle through which he conducts his private investment activities.\n\nHoward is an international entrepreneur and philanthropist, whose business interests originated in stockbroking and now span various alternative asset classes including real estate, technology and growth capital. He founded Shore Capital Group in 1985 and built it up to be one of London\'s leading independent investment groups specialising in principal finance, capital market activities and asset management. He remains chairman and majority shareholder, but relinquished operational responsibilities in 2017.\n\nHoward is also chairman of Spectrum Investments, a subsidiary of Shore Capital Limited, which owns a controlling interest in Deutsche Broadband Dienste GmbH (‘DBD’), a German telecoms business. DBD’s 32 regional radio spectrum licences have recently been reallocated to the 3.700-3.730 GHz frequency band on a ﬂexibilised basis, enabling their use for modern 4G and 5G services.\n\nHoward began his career in discretionary fund management with Grieveson Grant and Co., and subsequently became one of the first financial futures brokers when the Liffe market was established in 1982. Between 2001 and 2003, he served as a non-executive director at Tottenham Hotspur FC, and is a prominent supporter of the UK Conservative Party. Howard holds an M.A. in economics from Cambridge University, and his philanthropic interests cover healthcare, education and the arts. In 2022, Howard was appointed International Business Trustee of the Tate and in April 2023, he was appointed to the Board of the Prostate Cancer Foundation.'
    }, {
      'name': 'Brian White',
      'position': 'Director',
      'words': 'Brian White is a chartered tax adviser who advises a broad range of international clients. Prior to setting up his own practice, he was a senior Tax Partner with Deloitte and its predecessor firm for almost 30 years. He is sought out as a sounding board by many High Net Worth individuals across the world. He chairs a number of Charities including MedEquip4Kids, Unheard Voices Trust, Manchester Jewish Philanthropic and Manchester Jewish Community Centre. He established the Rainsough Charitable Trust and is an honorary adviser to numerous other charities. He was awarded an MBE by the Queen in 2021 for his services to charity over 30+ years.'
    }
  ]
  return (
    <div className="font-serif min-h-screen text-lg text-center text-navy">
      <SEO
        title="Home"
      />
      <header className="text-center bg-navy text-white p-6 text-center fixed header z-20 top-0 w-full" style={{transform: scrollY ? 'translateY(0)' : 'translateY(-100%)'}}>
        <img
          alt="PUMA BRANDENBURG"
          className="w-8 mx-auto mb-2"
          src={logo}
        />
        <h1 className="text-xl small-caps uppercase">Puma Brandenburg</h1>
      </header>
      <section className="px-6 min-h-screen homepage flex items-center justify-center text-navy fixed inset-0">
        <Link to="body" smooth={true} offset={-100}>
        <img
          alt=""
          style={{transform: 'translateX(-50%)'}}
          className="w-8 mx-auto mb-2 fixed bottom-0 mb-8 cursor-pointer"
          src={down}
        />
        </Link>
      </section>
      <div name="body" className="relative" style={{marginTop: '100vh'}}>

      <section className="px-6 text-center bg-navy text-white" >
        <div className="py-16 lg:py-32 mx-auto max-w-xl text-xl lg:text-2xl">
          <p>Puma Brandenburg Limited (“PBL”) is a diversified holding company with interests in a spread of alternative asset classes. These include real estate in Germany, the US and Israel, technology including medical devices, and growth capital for entrepreneurially led private businesses.</p>
        </div>
      </section>

      <section className="px-6 text-center footer">
      <div className="py-16 mx-auto max-w-2xl relative text-lg">
        <h2 className="mb-8">
          Company Information
        </h2>

<h3  className="font-semibold text-xl mb small-caps">Registered office</h3>
<p className="mb-6 text-xl">1 Royal Plaza, Royal Avenue, St Peter Port, <span>Guernsey GY1 2HL</span> </p>
<h3  className="font-semibold text-xl mb small-caps">Auditors</h3>
<p className="mb-6 text-xl">Grant Thornton Limited, PO Box 313 <br />
Lefebvre House, Lefebvre Street, St. Peter Port, <span className="inline-block">Guernsey, GY1 3TF</span></p>
<h3  className="font-semibold text-xl mb small-caps">Legal advisers</h3>
<p className="  text-xl">Carey Olsen, Carey House<br />
Les Banques, St Peter Port, <span className="inline-block">Guernsey, GY1 4BZ</span></p>
        </div>
      </section>
      <section className="px-6 text-center bg-navy text-white">
      <div className="py-16  mx-auto max-w-xl">
          <h2 className="mb-8 small-caps ">Directors</h2>
          <ul className="accordion">

          { people.map((person, key) => <li key={key} className={active.includes(key) ? 'active' : ''} >
              <header className="flex items-center cursor-pointer py-4" onClick={() => setActive(addOrRemove(active, key))}>
              <h3 className="flex-1 pr-4"><strong>{person.name} -</strong> {person.position}</h3>
              <button className="open" ></button>
              </header>
              <SlideDown className={'my-dropdown-slidedown'}>
                {active.includes(key) ? <div className="pt-4 pb-8" style={{whiteSpace: 'pre-wrap'}}>{person.words}</div> : null }
              </SlideDown>
            </li>)}

          </ul>
        </div>
      </section>
      <div className="py-16 px-6 text-center bg-navy text-white">
        <nav>
        <a className="inline-block px-4" href="/ESG Statement.pdf">ESG Statement</a> | 
        <a className="inline-block px-4" href="/Modern Slavery Statement.pdf">Modern Slavery Statement</a> | 
        <a className="inline-block px-4" href="/Legal Statement.pdf">Legal Statement</a>
        </nav>
      <p><small>&copy; {new Date().getFullYear()} Puma Brandenburg Ltd.</small></p>

      </div>
      </div>

    </div>
  );
}

export default IndexPage;
